import React from 'react';
import styles from './style.module.css';
import Fade from '@successtar/react-reveal/Fade';
import logo from '../Images/royalxlogo.png';

class About extends React.Component {
  render() {
    return (
      <div>
        <Fade cascade>
          <div className={styles.aboutBlock}>
            <h2 className={styles.aboutTitle}>Investing in Healthcare Royalties</h2>
            <h3 className={styles.aboutBody}>
              Healthcare royalties can add diversity to any traditional portfolio. As a high-quality alternative asset, a portfolio allocation of healthcare royalties can increase risk-adjusted returns.
            </h3>
            <div className={styles.investorsMid}>
            <h1 className={styles.investorsHeadline}>Uncorrelated</h1>
            <h3 className={styles.aboutBody}>
              When the markets dip, people still need to get treatment for diseases. Additionally, unlike equities, royalties don't suffer from labor, R&D, and other cyclical expenses.
            </h3>
            <h1 className={styles.investorsHeadline}>High Quality</h1>
            <h3 className={styles.aboutBody}>
              Commercial healthcare royalties have much more predictable returns than equities.
            </h3>
            </div>
            <h2 className={styles.aboutTitle2}>Commercial Royalties</h2>
            <h3 className={styles.aboutBody}>
              Commercial royalties are royalties on products that have already passed FDA approval.
            </h3>
            <h3 className={styles.aboutBody}>
              This includes drugs, therapies, and medical devices that are already on the market and ones that are preparing to launch.
            </h3>
            <div className={styles.homeSec3}>
            <h2 className={styles.aboutTitle3}>Clinical Royalties</h2>
            <h3 className={styles.aboutBody}>
              Clinical royalties are royalties on products that have not yet received FDA approval.
            </h3>
            <h3 className={styles.aboutBody}>
              Upon receiving FDA approval, clinical royalties convert to commercial royalties.
            </h3>
            <h3 className={styles.aboutBody}>
              Clinical royalties may be at various stages of testing and may have varying amounts of time left until they gain approval.
            </h3>
          </div>
          </div>
        </Fade>
        <div className={styles.bottomBar}>
          </div>
        <div className={styles.aboutBottom}>
          <div className={styles.flexContainer}>
            <div className={styles.flexBox}>
              <img className={styles.aboutBottomImg} src={logo} alt="logo"></img>
            </div>
            <div className={styles.flexBox}>
              <h3 className={styles.pageBottomText}>info@royalxinvestments.com</h3>
              <h3 className={styles.pageBottomText}>Tel: (929)543-9165</h3>
            </div>
            <div className={styles.flexBox}>
              <h3 className={styles.pageBottomHeadline}>
                <button onClick={() => { this.setState({ page: "contact" }) }} className={styles.bottomButton}>Contact Us</button>
              </h3>
              <h3 className={styles.pageBottomHeadline}>
                <button onClick={() => { this.setState({ page: "About" }) }} className={styles.bottomButton}>Learn More</button>
              </h3>
            </div>
          </div>
          <div className={styles.bottomBar}>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
