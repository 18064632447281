import React from 'react';
import styles from './style.module.css';
import banner from '../Images/bannerpictake2.jpg';
import Fade from '@successtar/react-reveal/Fade';
import logo from '../Images/royalxlogo.png';
import vials from '../Images/vials.jpg';
import Content from './Content'


class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'home'
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // Check if the previous page was not "home" and the current page is not "home"
    if (prevState.page !== 'home' && this.state.page !== 'home') {
      // Automatically change the page to "home"
      this.setState({ page: 'home' });
    }
  }
  render() {
    return (
      <div>
      {this.state.page==='home' ? (
        <div>
          <Fade cascade>
            <img className={styles.homeImg} src={banner} alt="banner"></img>
            <div className={styles.homeBlock}>
              <Fade left>
                <h1 className={styles.homeTitle}>Invest in Healthcare Royalties</h1>
              </Fade>
              <h2 className={styles.homeMission}>Healthcare royalties are high-quality alternative assets that can add diversity to any portfolio.</h2>
            </div>

            <div className={styles.homeMethodSpan}>
              <Fade bottom>
                <h2 className={styles.homeMethodTitle}>A strong alternative asset</h2>
              </Fade>
              <div className={styles.flexContainer}>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeMethodHeadline1}>$5b+</h3>
                  <h3 className={styles.homeMethod}>Money raised through healthcare royalty sales in 2023</h3>
                </div>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeMethodHeadline1}>$1.6t</h3>
                  <h3 className={styles.homeMethod}>Total pharmaceutical revenues in 2023</h3>
                </div>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeMethodHeadline1}>6%-10%</h3>
                  <h3 className={styles.homeMethod}>APR on commercial royalties</h3>
                </div>
              </div>
            </div>

            <div className={styles.homeContentSpan}>
              <div className={styles.flexContainer2}>
                <div className={styles.flexBox}>
                  <img className={styles.contentImg} src={vials} alt="vials"></img>
                </div>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeContent}>Buy and Sell Healthcare Royalties</h3>
                  <h2 className={styles.homeContentSub}>RoyalX is the first liquid marketplace for healthcare royalties.</h2>
                  <h2 className={styles.homeContentSub}>Get in on the ground floor of healthcare finance by purchasing commercial, clinical, and pre-clinical healthcare royalties.</h2>
                </div>
              </div>
            </div>

            <div className={styles.homeSec2}>
              <h3 className={styles.homeSec2Title}>Commercial Royalties</h3>
              <div className={styles.flexContainer}>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeSec2Body}>Immediate Returns</h3>
                  <h2 className={styles.homeSec2Sub}>Commercial Royalties are already FDA approved and in production</h2>
                </div>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeSec2Body}>6%-10% APR</h3>
                  <h2 className={styles.homeSec2Sub}>Average return on commercial royalties</h2>
                </div>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeSec2Body}>Low Risk</h3>
                  <h2 className={styles.homeSec2Sub}>Commercial royalties are already FDA approved</h2>
                </div>
              </div>
            </div>

            <div className={styles.homeSec3}>
              <h3 className={styles.homeSec2Title}>Clinical Royalties</h3>
              <div className={styles.flexContainer}>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeSec2Body}>Eventual Returns</h3>
                  <h2 className={styles.homeSec2Sub}>Royalties begin paying out after royalties turn commercial</h2>
                </div>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeSec2Body}>20%-60% APR</h3>
                  <h2 className={styles.homeSec2Sub}>Average return on clinical royalties</h2>
                </div>
                <div className={styles.flexBox}>
                  <h3 className={styles.homeSec2Body}>Higher Risk</h3>
                  <h2 className={styles.homeSec2Sub}>Clinical royalties face approval and commercial risk</h2>
                </div>
              </div>
            </div>
          </Fade>

          <div className={styles.topBar}>
            <div className={styles.topBarFill}></div>
          </div>

          <div className={styles.homeSec4}>
            <div className={styles.flexContainer2}>
              <div className={styles.flexBox}>
                <h1 className={styles.homeSec4Title}>Start Trading Healthcare Royalties Now.</h1>
              </div>
              <div className={styles.flexBox}>
                <h3 className={styles.pageBottomHeadline}>
                  <button onClick={() => { this.setState({ page: "contact" }) }} className={styles.bottomButton}>Contact Us</button>
                </h3>
                <h3 className={styles.pageBottomHeadline}>
                  <button onClick={() => { this.setState({ page: "about" }) }} className={styles.bottomButton}>Learn More</button>
                </h3>
                <h3 className={styles.pageBottomText}>info@royalxinvestments.com</h3>
                <h3 className={styles.pageBottomText}>Tel: (929)543-9165</h3>
              </div>
              <div className={styles.flexBox}>
                <img className={styles.bottomLogo} src={logo} alt="logo"></img>
              </div>
            </div>
          </div>

          <div className={styles.bottomBar}>
            <div className={styles.bottomBarFill}></div>
          </div>
        </div>
      ) : (
        <Content page={this.state.page} />
      )}
      </div>
    );
  }
}

export default Home;