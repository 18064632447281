import React from 'react';
import styles from './style.module.css';
import Fade from '@successtar/react-reveal/Fade';
import logo from '../Images/royalxlogo.png';

class About extends React.Component {
  render() {
    return (
      <div>
        <Fade cascade>
          <div className={styles.aboutBlockInv}>
            <h2 className={styles.aboutTitle}>Issuing Healthcare Royalties</h2>
            <h3 className={styles.aboutBody}>
              Royalty sales are a great alternative way to raise money and an important part of proper liability management.
            </h3>
            <div className={styles.investorsMid}>
              <h1 className={styles.investorsHeadline}>Non-dilutive</h1>
              <h3 className={styles.aboutBody}>
                Royalty sales are non-dilutive to equity holders, ensuring that investors keep their ownership of the company.
              </h3>
              <h1 className={styles.investorsHeadline}>Correlated with your success</h1>
              <h3 className={styles.aboutBody}>
                Unlike debt, royalty payments rise when you earn money and shrink when you don't, protecting you from debt-related fiscal issues.
              </h3>
              <h1 className={styles.investorsHeadline}>Transparent pricing</h1>
              <h3 className={styles.aboutBody}>
                By listing your royalty on RoyalX, you can ensure that you are getting the best price available on the market.
              </h3>
            </div>
            <div className={styles.investorsSec2}>
              <h2 className={styles.aboutTitle2}>Commercial Royalty Sales</h2>
              <h3 className={styles.aboutBody}>Commercial royalties are a sought-after asset.</h3>
              <h3 className={styles.aboutBody}>Allowing for price-discovery and public data guarantees the tightest spreads, giving you access to the most capital available.</h3>
              <div className={styles.homeSec3}>
              <h2 className={styles.aboutTitle3}>Clinical Royalty Sales</h2>
              <h3 className={styles.aboutBody}>Clinical royalties are assets on your balance sheet that can be utilized.</h3>
              <h3 className={styles.aboutBody}>Selling clinical royalties, along with venture equity and credit, are a great way to raise funds while keeping a diverse portfolio of liabilities.</h3>
            </div>
          </div>
          </div>
        </Fade>
        <div className={styles.bottomBar}>
          </div>
        <div className={styles.aboutBottom}>
          <div className={styles.flexContainer}>
            <div className={styles.flexBox}>
              <img className={styles.aboutBottomImg} src={logo} alt="logo"></img>
            </div>
            <div className={styles.flexBox}>
              <h3 className={styles.pageBottomText}>info@royalxinvestments.com</h3>
              <h3 className={styles.pageBottomText}>Tel: (929)543-9165</h3>
            </div>
            <div className={styles.flexBox}>
              <h3 className={styles.pageBottomHeadline}>
                <button onClick={() => { this.setState({ page: "contact" }) }} className={styles.bottomButton}>Contact Us</button>
              </h3>
              <h3 className={styles.pageBottomHeadline}>
                <button onClick={() => { this.setState({ page: "About" }) }} className={styles.bottomButton}>Learn More</button>
              </h3>
            </div>
          </div>
          <div className={styles.bottomBar}>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
