import React from 'react';
import styles from './style.module.css';
import linkedIn from '../Images/linkedIn.png';
import commspic from '../Images/commspic.jpg';
import emailIcon from '../Images/emailIcon.png';
import Fade from '@successtar/react-reveal/Fade';
import logo from '../Images/royalxlogo.png';


class Contact extends React.Component {
  render() {
    return (
      <div className={styles.careersBlock}>
        <Fade cascade>
          <img className={styles.contactBanner} src={commspic} alt="commspic"></img>
          <div className={styles.contactTitle}>
            <h1>Get in Touch</h1> 
          </div>
          
        </Fade>    
        <div className={styles.contactInfo}>
          <h3 className={styles.contactListing}>Tel: (929)543-9165</h3>
          <h3 className={styles.contactListing}> General Inquiry: <a href="mailto:info@royalxinvestments.com">info@royalxinvestments.com</a></h3>
          <h3 className={styles.contactListing}> Media Inquiry: <a href="mailto:media@royalxinvestments.com">media@royalxinvestments.com</a></h3>
          <h3 className={styles.contactListing}> Business Development: <a href="mailto:bizdev@royalxinvestments.com">bizdev@royalxinvestments.com</a></h3>
          <a href="https://www.linkedin.com/company/royalxinvestments?trk=public_profile_topcard-current-company" target="_blank" rel="noopener noreferrer">
            <img  className={styles.linkedInIconContact} src={linkedIn} alt="LinkedIn" />
          </a>
          <a href="mailto:info@royalxinvestments.com" target="_blank" rel="noopener noreferrer">
            <img  className={styles.emailIconContact} src={emailIcon} alt="Email" />
          </a>
        </div>
        <div className={styles.bar}>
        </div>

        <div className={styles.homeSec4}>
          <div className={styles.flexContainer2}>
            <div className={styles.flexBox}>
              <img className={styles.bottomLogo} src={logo} alt="logo"></img>

              {/* <h1 className={styles.homeSec4Title}>Start Trading Healthcare Royalties Now.</h1> */}
            </div>
            <div className={styles.flexBox}>
              <h3 className={styles.pageBottomText}>info@royalxinvestments.com</h3>
              <h3 className={styles.pageBottomText}>Tel: (929)543-9165</h3>
            </div>
          </div>

              {/* <img className={styles.bottomLogo} src={logo} alt="logo"></img> */}
        </div>

        <div className={styles.bar}>
        </div>
      </div>
    );
  }
}

export default Contact;
